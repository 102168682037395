import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchGlobalSettings } from '../api';

const GlobalSettingsContext = createContext();

export const GlobalSettingsProvider = ({ children }) => {
    const [globalSettings, setGlobalSettings] = useState(null);

    useEffect(() => {
        const getGlobalSettings = async () => {
            try {
                const settings = await fetchGlobalSettings();
                setGlobalSettings(settings);
            } catch (error) {
                console.error('Error fetching global settings:', error);
            }
        };

        getGlobalSettings();
    }, []);

    return (
        <GlobalSettingsContext.Provider value={globalSettings}>
            {children}
        </GlobalSettingsContext.Provider>
    );
};

export const useGlobalSettings = () => useContext(GlobalSettingsContext);
