import { Container } from './styles';
import { NavHashLink } from 'react-router-hash-link';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalSettings } from '../../context/GlobalSettingsContext';
import { useTheme } from '../../ThemeContext';
import { FaSun, FaMoon } from 'react-icons/fa';

export function Header() {
  const { isLightMode, toggleTheme } = useTheme();
  const [isActionActive, setActionActive] = useState(false);
  const globalSettings = useGlobalSettings();

  function closeMenu() {
    setActionActive(false);
  }

  if (!globalSettings) {
    return null; // or a loading spinner
  }

  return (
    <Container className="header-fixed">
      <NavHashLink smooth to="/#home" className="logo">
        <span>{"<Salman "}</span>
        <span>{" Khan/>"}</span>
      </NavHashLink>

      <nav className={isActionActive ? 'active' : ''}>
        <NavHashLink smooth to="/#home" onClick={closeMenu}>
          Home
        </NavHashLink>
        <NavHashLink smooth to="/#about" onClick={closeMenu}>
          About me
        </NavHashLink>
        <Link to="/projects" onClick={() => { closeMenu(); window.scrollTo(0, 0); }}>
          Projects
        </Link>
        <NavHashLink smooth to="/#contact" onClick={closeMenu}>
          Contact
        </NavHashLink>
        <div className="theme-toggle hidden md:flex" onClick={toggleTheme} aria-label="Toggle theme">
          {isLightMode ? <FaSun size={24} /> : <FaMoon size={24} />}
        </div>
        <a href={globalSettings.resume_url} target='_blank' rel="noreferrer" download="Salman_Khan_Resume.pdf" className="button">
          Resume
        </a>
      </nav>

      <div className="flex items-center md:hidden">
        <div className="theme-toggle mr-5" onClick={toggleTheme} aria-label="Toggle theme">
          {isLightMode ? <FaSun size={15} /> : <FaMoon size={15} />}
        </div>

        <div
          aria-expanded={isActionActive}
          aria-haspopup="true"
          aria-label={isActionActive ? 'Close menu' : 'Open menu'}
          className={`menu ${isActionActive ? 'active' : ''}`}
          onClick={() => setActionActive(prev => !prev)}
        />
      </div>
    </Container>
  );
}
