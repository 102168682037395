import { Container } from './styles';
import linkedin from '../../assets/linkedin.svg';
import whatsapp from '../../assets/whatsapp.svg';
import instagramIcon from '../../assets/instagram.svg';
import { useGlobalSettings } from '../../context/GlobalSettingsContext';

export function Footer() {
  const globalSettings = useGlobalSettings();

  if (!globalSettings) {
    return null; // or a loading spinner
  }

  return (
    <Container className="footer">
      <a href="https://thesalmankhan.com" className="logo">
        <span>www.thesalmankhan.com</span>
      </a>
      <div className="social-media">
        <a
          href={globalSettings.linkedin_url}
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="Linkedin" loading="lazy" />
        </a>
        <a
          href={`https://api.whatsapp.com/send/?phone=${globalSettings.whatsapp_number}&text=Hello+Salman`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="Whatsapp" loading="lazy" />
        </a>
        <a
          href={globalSettings.instagram_url}
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagramIcon} alt="Instagram" loading="lazy" />
        </a>
      </div>
    </Container>
  );
}
